<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <SideBar @close="openSidebar()" :active="'profile'"/>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button" @click="openSidebar">
                            <v-icon name="chevron-right" />
                        </button>
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-end align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/notif.png')" alt="" class="topbar-img my-auto">
                    </div>
                </div>
                <div class="profile-wrapper">
                    <h2 class="text-white">Profile</h2>
                    <div class="profile-unedit text-white" v-if="!isEditing">
                        <div class="info d-flex flex-wrap mb-5">
                            <div class="mr-3">
                                <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:90px;">
                            </div>
                            <div>
                                <h4 class="name">{{ init.profile.firstName }} {{ init.profile.lastName }}</h4>
                                <h6 class="email">{{ init.profile.emailAddress }}</h6>
                                <div class="info-status">{{ init.profile.verificationStatus }}</div>
                            </div>
                        </div>
                        <div class="align-items-end justify-content-end text-right text-white">
                            <h4 style="cursor:pointer;" @click="toggleEdit">Edit</h4>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="title">
                                <label for="firstName">Title</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.title" readonly>
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.firstName" readonly>
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">Last Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.lastName" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row2">
                                <label for="firstName">Date of Birth</label>
                                <div class="form-control">{{ init.profile.birthYear }}-{{ init.profile.birthMm }}-{{ init.profile.birthDd }}</div>
                            </div>
                            <div class="form-group" id="row2">
                                <label for="firstName">Gender</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.gender" readonly>
                            </div>
                            <div class="form-group" id="row2">
                                <label for="firstName">Citizenship</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.citizenship" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row3">
                                <label for="firstName">Occupation</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.occupation" readonly>
                            </div>
                            <div class="form-group" id="row3">
                                <label for="firstName">Nature of Work</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.natureOfWork" readonly>
                            </div>
                            <div class="form-group" id="row3">
                                <label for="firstName">Employer</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.employer" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="mobileEmail">
                                <label for="firstName">Mobile Number</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.mobilePhone" readonly>
                            </div>
                            <div class="form-group" id="mobileEmail">
                                <label for="firstName">Email Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row4">
                                <label for="firstName">Country / Region</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.presentAddress.country" readonly>
                            </div>
                            <div class="form-group" id="row4">
                                <label for="firstName">City / Town</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.presentAddress.cityTown" readonly>
                            </div>
                            <div class="form-group" id="row4">
                                <label for="firstName">Number / Street</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.presentAddress.numberStreet" readonly>
                            </div>
                        </div>
                        <div action="" class="input-group mt-3 text-white gov-id" v-for="(poid, index) in poids" :key="poid.id">
                            <div class="input-group-prepend">
                                <label for="firstName" style="display:block;" v-if="index == 0">ID Type</label>
                                <input type="text" class="form-control text-white" v-model="poid.poidType" disabled>
                            </div>
                            <div class="form-group">
                                <label for="firstName" v-if="index == 0">Description</label>
                                <input type="text" class="form-control text-white" v-model="poid.poidDesc">
                            </div>
                            <div class="input-group-append status">
                                <label for="firstName" v-if="index == 0">Status</label>
                                <span class="input-group-text">{{ poid.approvalStatus }}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <button class="btn btn-main" style="width:20%" @click="uploadPoid()">UPLOAD</button>
                        </div>
                    </div>
                    <div class="profile-unedit text-white" v-if="isEditing">
                        <div class="info d-flex flex-wrap mb-5">
                            <div class="mr-3" style="position:relative;padding:10px;" @click="uploadPfp">
                                <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:90px;">
                                <button class="btn" style="position:absolute;top:0;right:0;background-color:blue;fill:white;">
                                    <v-icon name="pen"/>
                                </button>
                            </div>
                            <div class="my-auto">
                                <h4 class="name">{{ init.profile.firstName }} {{ init.profile.lastName }}</h4>
                                <h6 class="email">{{ init.profile.emailAddress }}</h6>
                                <div class="info-status">{{ init.profile.verificationStatus }}</div>
                            </div>
                        </div>
                        <div class="align-items-end justify-content-end text-right text-white">
                            <h4 style="cursor:pointer;" @click="toggleEdit">Cancel</h4>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="title">
                                <label for="firstName">Title</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="init.profile.title" required>
                                    <option value="Mr">Mr</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mrs">Mrs</option>
                                </select>
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.firstName">
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">Last Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.lastName">
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row2">
                                <label for="firstName">Date of Birth</label>
                                <DatePicker type="date" format="YYYY-MM-DD" valueType="format" v-model="profile.birthDate" required></DatePicker>
                            </div>
                            <div class="form-group" id="row2">
                                <label for="firstName">Gender</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.gender">
                            </div>
                            <div class="form-group" id="row2">
                                <label for="firstName">Citizenship</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.citizenship">
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row3">
                                <label for="firstName">Occupation</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.occupation">
                            </div>
                            <div class="form-group" id="row3">
                                <label for="firstName">Nature of Work</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.natureOfWork">
                            </div>
                            <div class="form-group" id="row3">
                                <label for="firstName">Employer</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.employer">
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" style="position:relative;" id="mobileEmail">
                                <label for="exampleFormControlInput1">Mobile Number</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn text-white" type="button" v-on:click="toggleCountryList">
                                            <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
                                            <span>+{{ mobileCode }}</span>
                                        </button>
                                    </div>
                                    <input type="tel" class="form-control" @keypress="isNumber($event)" v-model="init.profile.mobilePhone">
                                </div>
                                <div class="country-list" v-if="showCountry">
                                    <div class="row">
                                        <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)">
                                            <div>
                                            <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                                            </div>
                                            <div class="country-name"> <span> {{ l.country }} </span> </div>     
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" id="mobileEmail">
                                <label for="firstName">Email Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress">
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row4">
                                <label for="firstName">Country / Region</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="init.profile.presentAddress.country" required>
                                    <option :value="l.countryCode" v-for="l in list.mobileCodes" :key="l.id">{{ l.country }}</option>
                                </select>
                            </div>
                            <div class="form-group" id="row4">
                                <label for="firstName">City / Town</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.presentAddress.cityTown" readonly>
                            </div>
                            <div class="form-group" id="row4">
                                <label for="firstName">Number / Street</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.presentAddress.numberStreet" readonly>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <button class="btn btn-main mt-3" style="width:20%" @click="saveProfile">SAVE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChangePin @close="exitModal"/>
        <ConfirmLogout />
        <UploadPfp />
        <UploadPoid :type="type" :label="label" @uploaded="update()"/> 
    </div>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import UploadPfp from '@/components/modal/UploadPfp.vue'
import DatePicker from 'vue2-datepicker';
import UploadPoid from '@/components/modal/UploadPoid.vue'
import SideBar from '@/components/SideBar.vue'
import 'vue2-datepicker/index.css';
export default {
    components: {
        ChangePin,
        ConfirmLogout,
        DatePicker,
        UploadPfp,
        UploadPoid,
        SideBar
    },
    data() {
        return {
            sideBar: false,
            modalState: 'main',
            hasMobile: false,
            isSaved: false,
            country: 'PH',
            mobileCode: '63',
            showCountry: false,
            list: {
            mobileCodes: []
            },
            isSaving: {
                profile: false,
                contact: false,
                account: false
            },
            isEditing: false,
            config: {
                file: null,
                id: ''
            },
            errors: {
                file: '',
                profile: ''
            },
            poids: {},
            profile: {
                birthDate: ''
            },
            pfpString: ""
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        update() {
            window.location.reload();
        },
        openSidebar() {
            this.sideBar = !this.sideBar
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },

        uploadPoid() {
            console.log('.')
            this.$bvModal.show('upload-poid-modal'); 
        },

        exitModal() {
            this.modalState = 'main'
        },
        viewPrivateKey() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal');
        },
        uploadPfp() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('upload-pfp-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        dashboard() {
            this.$router.push('/')
        },
        transactions() {
            this.$router.push('/transactions')
        },
        toggleEdit() {
            this.isEditing = !this.isEditing
        },
        toggleCountryList: function(){
            this.showCountry = !this.showCountry;
        },

        uploadPoid(type, label) {
            console.log('.')
            this.type = type
            this.label = label
            this.$bvModal.show('upload-poid-modal'); 
        },

        getMobileCodes: function() {
            this.axios.get('/assets/mobilecodes.json')
                .then((response) => {  
                    this.list.mobileCodes = response.data; 
                }).catch((err) => {
                    console.log(err);
                })
        },

        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.mobileCode = c.mobileCode; 
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        getPoids() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.poids = response.data
                
                })
                .catch((error) => {
                    
                });
        },
        getPfp() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/showavatar',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.pfpString = response.data
                })
                .catch((error) => {
                    
                });
        },
        saveProfile: function() {
            this.isSaving = true
            let birthYear = this.profile.birthDate.substring(0, this.profile.birthDate.indexOf('-'))
            let birthMm = this.profile.birthDate.substring(this.profile.birthDate.indexOf('-') + 1, this.profile.birthDate.indexOf('-') + 3)
            let birthDd = this.profile.birthDate.substring(this.profile.birthDate.indexOf('-') + 4, this.profile.birthDate.length)
            const params = {
                birthDd: birthDd,
                birthMm: birthMm,
                birthYear: birthYear,
                firstName: this.init.profile.firstName,
                homePhone: '-',
                lastName: this.init.profile.lastName,
                employer: this.init.profile.employer,
                homePhone: '-',
                identityNumber: '-',
                maritalStatus: '-',
                middleName: '-',
                mothersMaidenName: '-',
                nationality: this.init.profile.presentAddress.country,
                natureOfWork: this.init.profile.natureOfWork,
                occupation: this.init.profile.occupation,
                'permanentAddress.addressExtraInfo': '-',
                'permanentAddress.cityTown': this.init.profile.presentAddress.cityTown,
                'permanentAddress.country': this.init.profile.presentAddress.country,
                'permanentAddress.numberStreet': this.init.profile.presentAddress.numberStreet,
                'permanentAddress.postalCode': this.init.profile.presentAddress.postalCode,
                'permanentAddress.provState': this.init.profile.presentAddress.provState,
                placeOfBirth: '-',
                'presentAddress.addressExtraInfo': '',
                'presentAddress.cityTown': this.init.profile.presentAddress.cityTown,
                'presentAddress.country': this.init.profile.presentAddress.country,
                'presentAddress.numberStreet': this.init.profile.presentAddress.numberStreet,
                'presentAddress.postalCode': this.init.profile.presentAddress.postalCode,
                'presentAddress.provState': this.init.profile.presentAddress.provState,
                sourceOfFunds: '-',
                title: this.init.profile.title

            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/profile/updatebasic',
            };

            this.axios(options)
                .then(() => { 
                    this.isSaved = true
                    this.getProfile();
                    this.isEditing = false
                }).catch((err) => {
                    this.isSaving = false
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        updateEmail: function() {
    
            const params = {
                emailAddress: this.init.profile.emailAddress,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updateemail',
            };

            this.axios(options)
                .then(() => { 
                    this.getProfile();
                }).catch((err) => {
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        updateMobile: function() {
    
            const params = {
                mobilePhone: this.init.profile.mobilePhone,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updatemobile',
            };

            this.axios(options)
                .then(() => { 
                    this.getProfile();
                }).catch((err) => {
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        onUpload: function(){
            let hasError = false;
                if(this.config.file == '' || this.config.file == undefined || this.config.file == null) {
                    this.errors.file = 'File is required.';
                    hasError = true;
                }  

                if(this.config.id == '' || this.config.id == undefined || this.config.id == null) {
                    this.errors.file = 'ID is required.';
                    hasError = true;
                }  

                if(!hasError) {
                    this.showError = false;
                    let formData = new FormData();
                    formData.append('poidType', "ACRICR"); 
                    formData.append('poidDescription', "ACRICR");
                    formData.append('poidNumber', this.config.id);
                    formData.append('poidImageFile', this.config.file);

                    this.axios.post('/cx/uploadpoid',
                                    formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => { 

                    this.showError = true;
                        this.systemMsg = "Config uploaded successfully";
                        this.config.file = null;  
                        this.$emit("close")
                        this.$emit("done")
                        
                    }).catch((err) => {
            
            
                        if(err) {
                        this.showError = true;
                        this.systemMsg = err.response.data.msgText;
                        setTimeout(() => {
                            this.errors.file = ""
                        }, 5000)
                        } 

                    })
                }

        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                },1000)
                    
                }  

                })
        },
        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {
                this.getPoids();
                this.init.profile = response.data;
                if(this.init.profile.mobileNumber == null) {
                    this.hasMobile = false
                } else {
                    this.hasMobile = true
                }


                    
                })
        },
    },
    mounted() {
        this.checkSession();
        this.getMobileCodes();
        this.getPfp();
    }
}
</script>

<style scoped lang="scss">
    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 100px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .profile-wrapper {
                padding:50px 70px;
                .profile-unedit {
                    width:100%;
                    padding:30px;
                    border-radius:10px;
                    #title {
                        width:15%;
                    }
                    #name {
                        width:40%;
                    }
                    #row2 {
                        width:31.66%;
                    }
                    #row3 {
                        width:31.66%;
                    }
                    #mobileEmail {
                        width:47.5%;
                    }
                    #row4 {
                        width:31.66%;
                    }
                    .name {
                        margin:0;
                    }
                    .info-status {
                      background:#0567d7;
                      text-align:center;
                      padding:5px;
                      border-radius:5px;
                      width:10em;  
                    }
                    .default-flag {
                        width: 33px;
                        margin-right:0.25em;
                    }

                    .country-list {
                        position: absolute;
                        background: #ccc;
                        color: #333;
                        top: 70px;
                        padding: 0.5em;
                        height: 13em;
                        overflow: auto;
                        z-index: 3;
                        .row {
                            margin: 0 !important;
                            .col-sm-6 {
                                display: flex;
                                padding: 0.5em;
                                align-items: center;
                                cursor: pointer;
                                &:hover {
                                    background: darken(#ccc, 10%);
                                }
                                .country-name {
                                    margin-left: 0.5em;
                                }

                                span {
                                    font-size: 0.9em;
                                }
                            }
                        }
                    }

                    .flag-list-img {
                    width: 33px;
                    }

                    .mx-datepicker  {
                        width:100%;
                    }
                    .gov-id {
                        overflow:auto;
                        .input-group-prepend {
                            display:block;
                            width:35%;
                            input {
                                background:none;
                            }
                        }
                        .form-group {
                            width:45%;
                        }
                        input {
                            border:1px #0567d7 solid;
                            background:none;
                        }
                        
                        .input-group-append {
                            width:20%;
                            display:block;
                            height:calc(1.5em + 0.75rem + 2px);
                            .input-group-text {
                                width:100%;
                                background:none;
                                border:1px #0567d7 solid;
                                color:white;
                                font-family:SSN-Light;
                                text-align:center;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:800px) {
        .wrapper {
            .main-body {
                .profile-wrapper {
                    padding:20px 20px;
                    .profile-unedit {
                        #title {
                            width:100%;
                        }

                        #name {
                            width:100%;
                        }

                        #row2 {
                            width:100%;
                        }

                        #row3 {
                            width:100%;
                        }

                        #mobileEmail {
                            width:100%;
                        }

                        #row4 {
                            width:100%;
                        }

                        .gov-id {
                            .form-group {
                                width:35%;
                            }
                            .input-group-append {
                                width:30%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:650px) {
        .wrapper {
            .main-body {
                padding:10px;
                .profile-wrapper {
                    padding:0px;
                    .profile-unedit {
                        padding:10px;
                        .gov-id {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
</style>