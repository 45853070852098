<template>
    <div class="sidebar">
        <div class="align-items-end justify-content-end text-right text-white">
        <h4 style="cursor:pointer;" @click="openSidebar">x</h4>
    </div>
    <div class="text-center">
        <img :src="require('../assets/id-logo.png')" style="margin-bottom:40px;width:15em;" class="mx-auto text-center">
    </div>
    <div class="sidebar-link d-flex flex-wrap " :class="{active: active == 'dashboard'}" @click="dashboard">
        <img :src="require('../assets/icon-dash.png')" alt="" class="sidebar-link-img">
        <h4 class="sidebar-link-text">Dashboard</h4>
    </div>
    <div class="sidebar-link d-flex flex-wrap" :class="{active: active == 'profile'}" @click="profile">
        <img :src="require('../assets/profile.png')" alt="" class="sidebar-link-img">
        <h4 class="sidebar-link-text">Profile</h4>
    </div>
    <!-- <div class="sidebar-link d-flex flex-wrap" @click="transactions">
        <img :src="require('../assets/transactions.png')" alt="" class="sidebar-link-img">
        <h4 class="sidebar-link-text">Requests</h4>
    </div> -->
    <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'changePin'}" @click="changePin">
        <img :src="require('../assets/change pipn.png')" alt="" class="sidebar-link-img">
        <h4 class="sidebar-link-text">Change Pin</h4>
    </div>
    <!-- <div class="sidebar-link d-flex flex-wrap">
        <img :src="require('../assets/support.png')" alt="" class="sidebar-link-img">
        <h4 class="sidebar-link-text">Support</h4>
    </div> -->
    </div>
</template>

<script>
export default {
    props: ["active"],
    data() {
        return {
            modalState: 'main',
            state: 'main-wallet',
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        openSidebar() {
            this.$emit('close')
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },
        exitModal() {
            this.modalState = 'main'
        },
        viewPrivateKey() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        dashboard() {
            this.$router.push('/')
        },
        profile() {
            this.$router.push('/profile')
        },
    }
}
</script>

<style scoped lang="scss">
    .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
</style>